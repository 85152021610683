* {
    animation: fadeIn ease 250ms;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.filepond--credits {
    display: none;
}

a {
    text-decoration: none;
}
